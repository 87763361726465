<template>
<div>
  <my-header/>
  <div v-if="notFound">
    <!-- Show your 404 content. -->
    Page not found
  </div>
  <div v-else>
    <render-content
      :key="$route.path"
      model="page"
      @contentLoaded="contentLoaded"
      @contentError="contentError"
      :options="{
        url: $route.path,
      }"
    />
  </div>
  <my-footer/>
</div>
</template>

<script>
import { builder, RenderContent } from '@builder.io/vue';
import Vue from 'vue';
import { getLog } from '@/services/log';
import Header from '../guest/Header.vue';
import Footer from '@/components/Footer.vue';
let log = getLog("test-builder");

// Enter your Builder.io public API key
builder.init("1c330c1e07d145c398b4bce6ee0ad4eb");

export default Vue.extend({
  components: { 
    RenderContent,
    MyHeader: Header,
    MyFooter:Footer,
  },
  data() {
    return {
      notFound: false,
    }
  },
  mounted() {
    log.log("mounted");
  },
  methods: {
    contentLoaded(content) {
      if (!content) {
        log.log("contentLoaded no content");
        if (this.$nuxt?.context.ssrContext) {
          this.$nuxt.context.ssrContext.res.statusCode = 404
        }
        this.notFound = true
      } else {
        log.log("contentLoaded", content.name);
      }
    },
    contentError(err) {
      log.log("error", err);
    },
  },  
});
</script>