<template>
  <div class="fixed-bottom" style="pointer-events: none;">
    <div class="clearfix">
    <div class="small text-muted bg-light d-inline p-1 rounded-top" style="pointer-events: auto;">
      &nbsp;{{ $app.productNameLC }}{{ appVariant }}@{{ appVersion }}
      <div class="d-inline" v-if="$debug.isAvailable">
        - debug <input type="checkbox" v-model="$debug.isOn"/> - low def <input type="checkbox" v-model="$debug.lowDef"/>
      </div>
    </div>
    </div>
  </div>  
</template>

<script>
export default {
  data() {
    return {
      appVersion: require("@/../package.json").version,
      appVariant: "",
    }
  }
}
</script>

<style>

</style>