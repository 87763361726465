<template>
<div>
  <!-- guest sidebar : left -->
  <b-sidebar id="sidebar-1" shadow>
      <div class="px-3 py-2">
        <nav class="mb-2">
            <b-nav vertical pills>
              <b-nav-item href="/guest" exact active-class="active">Main</b-nav-item>
              <b-nav-item href="/guest/events/create" exact active-class="active" v-if="canCreateEvents && $app.menuItems.includes('events')">Create Event</b-nav-item>
              <b-nav-item href="/guest/events" exact active-class="active" v-if="$app.menuItems.includes('events')">Events</b-nav-item>
              <b-nav-item href="/guest/profile" exact active-class="active" v-if="user">Profile</b-nav-item>
              <b-nav-item href="/guest/matches" exact active-class="active" v-if="$app.menuItems.includes('matches')">Matches</b-nav-item>
              <b-nav-item href="/guest/account" exact active-class="active" v-if="$app.menuItems.includes('account')">Account</b-nav-item>
              <br/>
              <br/>
              <b-nav-item v-if="$debug.isAvailable" align="bottom" href="/guest/debug">Debug</b-nav-item>
            </b-nav>
        </nav>
      </div>
  </b-sidebar>
  <!-- Main part -->
  <div>
    <!-- Header -->
    <div class="sticky-top bg-white">
      <div class="position-absolute float-left burger-button" v-b-toggle.sidebar-1 v-if="!hideNav">
        <font-awesome-icon icon="bars"/>
      </div>
      <div class="text-center">
        <div class="p-2" style="height:42px;">
          <img v-if="!hideTitle" :srcset="$app.productLogoTitle + ' 2x'"/>
          <b-badge v-if="$app.environment" variant="success">{{ $app.environment }}</b-badge>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style>

.burger-button {
  left: 16px;
  top: 10px;
}

</style>